<template>
  <div class="mymapM">
    <div class="van_nav_view">
      <van-nav-bar left-arrow>
        <template #left>
          <img
            style="width: 18px; height: 18px"
            src="../../../assets/black_return_view_bg.png"
            @click="$router.go(-1)"
            alt=""
          />
        </template>
        <template #title>
          <span style="color: black; font-size: 18px">收货地址</span>
        </template>
        <template
          #right
          style="display: flex; flex-direction: row; align-items: center"
        >
          <span style="font-size: 14px; color: #18b271" @click="toPage(1)"
            >新增地址</span
          >
        </template>
      </van-nav-bar>
      <div class="search_all_view">
        <div class="search_view">
          <img src="../../../assets/gray_address_bg.png" alt="" />
          <input type="text" placeholder="请输入收货地址" />
        </div>
      </div>

      <!-- <iframe
        id="mapPage"
        width="100%"
        height="700px"
        frameborder="0"
        src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=3AABZ-RG3LX-2LT4M-ZPY3C-OIYVV-RFFWW&referer=myapp"
      >
      </iframe> -->
    </div>
    <div class="songzhi_view">
      <span>送至</span>
    </div>
    <div class="sz_address_view" @click="toHome">
      <img src="../../../assets/location_map_bg.png" alt="" />
      <span>{{ address }}</span>
    </div>
    <div class="my_shouhuo_view">
      <span>我的收货地址</span>
    </div>
    <div class="address_bg_view" @click="toHome">
      <div
        class="address_my_list_view"
        v-for="(addressItem, addressIndex) in addressList"
        :key="addressIndex"
      >
        <div class="address_list_left_view">
          <span>{{ addressItem.address }} <span>{{ addressItem.houseNumber }}</span> </span>
          <span>{{ addressItem.phone }}</span>
        </div>
      </div>
    </div>
    <div class="near_address_view">
      <span>附近地址</span>
    </div>
    <div class="near_bg_view" @click="toHome">
      <div
        class="near_list_view"
        v-for="(poiItem, poiIndex) in poiList"
        :key="poiIndex"
      >
        <span class="poi_address_tv_view">{{ poiItem.address }}</span>
        <div class="position_right_view" v-show="poiIndex === 0">
          <img src="../../../assets/position_icon.png" alt="" />
          <span>重新定位</span>
        </div>
      </div>
    </div>
    <iframe
      id="geoPage"
      width="0"
      height="0"
      frameborder="0"
      style="display: none"
      scrolling="no"
      src="https://apis.map.qq.com/tools/geolocation?key=3AABZ-RG3LX-2LT4M-ZPY3C-OIYVV-RFFWW&referer=shengxian"
    >
    </iframe>
  </div>
</template>

<script>
import IconView from "../../../components/IconView.vue";
import { receiptGetList } from "@/service/api/index.js";
import { ceshi } from "@/service/api/index.js";
export default {
  components: { IconView },
  data() {
    return {
      checked: true,
      search_key: "", //搜索值
      lists: [], //地点列表
      search_list: [], //搜索结果列表
      marker: "",
      lat: "",
      lng: "",
      address: "",
      poiList: [],
      addressList: [],
      noSearchShow: false, //无搜索结果提示，无搜索结果时会显示暂无搜索结果
      radio:"",
    };
  },
  created() {


  },
  mounted() {
    // const that_ = this;
    // window.addEventListener(
    //   "message",
    //   function (event) {
    //     // console.log(event)
    //     // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
    //     var loc = event.data;
    //     console.log(loc);
    //     if (loc && loc.module == "locationPicker") {
    //       //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
    //       // console.log("location", loc);
    //       // console.log(loc);
    //       that_.$router.push({
    //         path: "newAddress",
    //         query: {
    //           address: loc.poiaddress,
    //         },
    //       });
    //     }
    //   },
    //   false
    // );
    this.receiptGetList();
  },
  methods: {
    location() {
      var isMapInit = true;
      var that = this;
      window.addEventListener(
        "message",
        function (event) {
          // 接收位置信息
          var data = event.data;
          if (data && data.module == "geolocation") {
            //定位成功,防止其他应用也会向该页面post信息，需判断module是否为'geolocation'
            if (isMapInit) {
              that.lat = data.lat;
              that.lng = data.lng;
              isMapInit = false;
            }
          } else {
            //定位组件在定位失败后，也会触发message, event.data为null
            // console.log("定位失败");
          }
        },
        false
      );
    },
    receiptGetList() {
      receiptGetList().then((res) => {
        if (res.status == 200) {
          this.addressList = res.data;
          let list = this.addressList.filter(
            (el) => el.isDefault === "ENABLE"
          );
          if (list.length) {
            // this.radio = list[0].id;
            this.address = list[0].address
          }
        }
      });
    },
    onChange(event) {
      this.setData({
        checked: event.detail,
      });
    },
    toPage() {
      this.$router.push("/newAddress");
    },
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="less" scoped>
.van_nav_view {
  max-width: 540px;
  width: 100%;
  height: 100px;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 20;
}
.search_input_view {
  width: 90%;
  height: 30px;
  border-radius: 20px;
  margin: 0 auto;
  background: white;
  border: 1px solid #00b05a;
  display: flex;
  flex-direction: row;
  line-height: 30px;
  align-items: center;
  .icon_view {
    width: 10.88px;
    height: 16px;
    margin-top: 8px;
    margin-left: 10px;
  }
  input {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: none;
  }
}
.list_view {
  width: 100%;
  margin-top: 95px;
  .arrive_view {
    .arrive_view_span {
      width: 96%;
      height: 26px;
      display: block;
      background: #f1f5f6;
      margin-left: 15px;
      font-size: 14px;
      color: #787d84;
      line-height: 26px;
    }
    div:last-of-type {
      width: 100%;
      height: 44px;
      background: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-left: 12px;
      }
      span {
        font-size: 15px;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
  .receiving_address_view {
    .my_receiving_address_view {
      width: 96%;
      height: 26px;
      display: block;
      background: #f1f5f6;
      font-size: 14px;
      margin-left: 15px;
      color: #787d84;
      line-height: 26px;
    }
    .my_address_view {
      width: 100%;
      height: 64px;
      background: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .my_address_left_view {
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 15px;

          span {
            font-size: 15px;
            display: block;
            color: black;
          }
          span:last-of-type {
            width: 24px;
            height: 12px;
            display: block;
            background: rgba(250, 100, 0, 0.1);
            border-radius: 2px;
            border: 1px solid #fa6400;
            line-height: 12px;
            text-align: center;
            margin-left: 2px;
            font-size: 10px;
            color: #fa6400;
          }
        }
        .number_view {
          font-size: 12px;
          color: #6d7278;
          margin-left: 15px;
          margin-top: 3px;
        }
      }
    }
  }
  .fj_view {
    .fj_address_view {
      width: 96%;
      height: 26px;
      background: #f1f5f6;
      padding-left: 15px;
      font-size: 14px;
      color: #787d84;
      line-height: 26px;
    }
    .fj_location_address_list_view {
      width: 100%;
      height: 44px;
      background: white;
      margin-right: 12px;
      .fj_location_view {
        display: flex;
        margin-right: 10px;
        flex-direction: row;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
.search_all_view {
  width: 100%;
  background: white;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_view {
  width: 100%;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #00b05a;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
  box-sizing: border-box;
}
.search_view img {
  width: 16px;
  height: 16px;
  margin-left: 7px;
}
.search_view input {
  flex: 1;
  margin-left: 4px;
  border: none;
  background: none;
  font-size: 14px;
}
.search_view input::-webkit-input-placeholder {
  font-size: 12px;
}
.songzhi_view {
  width: 100%;
  height: 26px;
  background: #f1f5f6;
  display: flex;
  align-items: center;
  margin-top: 100px;
}
.songzhi_view span {
  margin-left: 15px;
  font-size: 14px;
  color: #787d84;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.sz_address_view {
  width: 100%;
  height: 44px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sz_address_view img {
  width: 16px;
  height: 16px;
  margin-left: 12px;
}
.sz_address_view span {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-left: 8px;
}
.my_shouhuo_view {
  width: 100%;
  height: 26px;
  background: #f1f5f6;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.my_shouhuo_view span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #83898f;
  margin-left: 15px;
}
.address_bg_view {
  // margin-top: 210px;
  width: 100%;
  background: white;
}
.address_my_list_view {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  justify-content: space-between;
}
.address_my_list_view img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
.address_list_left_view {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.address_list_left_view span:first-child {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
.address_list_left_view span:last-child {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6d7278;
}
.near_address_view {
  width: 100%;
  height: 26px;
  background: #f1f5f6;
  display: flex;
  align-items: center;
}
.near_address_view span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #83898f;
  margin-left: 15px;
}
.near_bg_view {
  width: 100%;
  background: white;
}
.near_list_view {
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.poi_address_tv_view {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-left: 15px;
}
.position_right_view {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.position_right_view img {
  width: 16px;
  height: 16px;
}
.position_right_view span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #00b05a;
}
</style>